// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
//import './plugins/lazyload';
import './plugins/modernizr.min';
//import './plugins/google-map';
import 'jquery-match-height';
import LazyLoad from 'vanilla-lazyload';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
import barba from '@barba/core';
// import ScrollOut from 'scroll-out';

/* global Swiper */
/* global ajax_object */

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * BarbsJS init
   */
  const $body = $(document.body);
  if ($body.data('barba') === 'wrapper') {
    barba.init({
      timeout: 10000,
      transitions: [
        {
          name: 'basic',
          enter: function enter() {
            // Init LazyLoad (for ignore .no-lazyload)
            //lazyLoadInit();
          },
          beforeEnter: function beforeEnter() {
            $('html, body').animate({ scrollTop: 0 }, 600);
          },
          after: function after() {
            let pageEdit = $(document).find('#bwp-main').data('edit');
            if (pageEdit) {
              $(document).find('#wp-admin-bar-edit > a').attr('href', pageEdit);
            }

            let $formWrapper = $('.form-wrapper');
            if ($formWrapper.length) {
              let formID = $formWrapper.attr('data-form-id');

              $.ajax({
                url: ajax_object.ajax_url,
                type: 'GET',
                data: {
                  form_id: formID,
                  action: 'get_form',
                },
              }).done(function (response) {
                $('.form-wrapper').html(response);

                setTimeout(function () {
                  $formWrapper.find('script').each((index, el) => {
                    window.eval($(el).text());
                  });
                }, 2000);
              });
            }

            // Update elements
            lazyLoadInit();
            slidersInit();
            resizeVideo();
          },
        },
      ],
      prevent: ({ el }) =>
        el.classList &&
        (el.classList.contains('ab-item') ||
          el.classList.contains('fancybox-image') ||
          el.hasAttribute('data-fancybox')),
    });
  }

  /**
   * Add fancybox to images
   * a[href$="jpg"], a[href$="png"], a[href$="gif"], a[href$="webp"]
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"], a[href$="webp"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox], .fancybox, a[rel*="album"]');

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /*$(document).on('gform_post_render', () => {
  });*/

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 100 }, 1000);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Override bootstrap submenu toggle
   */
  $('.navbar .menu-item-has-children').hover(function () {
    if ($(window).width() > 991) {
      $(this).toggleClass('show');
      alignMenuDropdown($(this).children('.dropdown-menu'));
    }
  });

  // Prevent dropdown menu opening out of window
  function alignMenuDropdown($dropdown) {
    if ($dropdown === undefined || !$dropdown.length) return false;
    $dropdown.toggleClass(
      'dropdown-menu-left',
      $dropdown.offset().left + $dropdown.width() > $(window).width()
    );
  }

  // Dropdown menu mobile toggle
  $('.js-dropdown-toggle').bind('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    if ($(window).width() <= 991) {
      $(this).closest('.dropdown').toggleClass('show');
    }
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  // Apply scrolling classes on autoscroll
  $(window).trigger('scroll');

  lazyLoadInit();
  slidersInit();
  scrollToAnchor();
  resizeVideo();
}); // END of document ready

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  // Optimize home slider (mobile)
  setTimeout(() => {
    let $sliderPreview = $('#home-slider-preview');
    let $homeSlider = $('#home-slider');
    if (
      $(window).width() <= 768 &&
      $sliderPreview.length &&
      $homeSlider.length
    ) {
      $sliderPreview.hide();
      $homeSlider.show();
    }
  }, 1200);
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $(document.body)
    .toggleClass('scrolling-up', st < lastScrollTop)
    .toggleClass('scrolling-down', st > lastScrollTop)
    .toggleClass('scrolling', st !== 0);
  if (st === 0) $('body').removeClass('scrolling-up scrolling-down');
  lastScrollTop = st;
});

/* Scrolling to anchor */
function scrollToAnchor() {
  // Anchor click scrolling
  let topOffset = $(window).width() > 991 ? 100 : 70;
  $(document).on('click', 'a[href^="#"]', function (e) {
    if (
      $(this).attr('href') != '#' &&
      !$(this).hasClass('fancybox') &&
      $(this).attr('role') != 'tab'
    ) {
      e.preventDefault();
      let $this = $(this);
      if ($($this.attr('href')).length) {
        $('html, body').animate(
          {
            scrollTop: $($this.attr('href')).offset().top - topOffset + 'px',
          },
          750,
          'swing'
        );
      } else if ($this.attr('href') === '#next') {
        let $sectionToScroll = $this.closest('section').next('section');

        if ($sectionToScroll.length) {
          $('html, body').animate(
            {
              scrollTop: $sectionToScroll.offset().top - topOffset + 'px',
            },
            750,
            'swing'
          );
        }
      }
    }
  });

  // On load *only* if we have anchor on the url
  if (window.location.hash) {
    // smooth scroll to the anchor id
    $('html, body').animate(
      {
        scrollTop: $(window.location.hash).offset().top - topOffset + 'px',
      },
      750,
      'swing'
    );
  }
}

/* Sliders init */
function slidersInit() {
  // Init Swiper
  if ($('.js-home-slider').length && typeof Swiper !== undefined) {
    new Swiper('.js-home-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 3000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      speed: 1500,
      spaceBetween: 0,
      slidesPerView: 1,
    });
  }

  // Init client slider
  let $client_sliders = $('.js-clients-slider');
  if ($client_sliders.length && typeof Swiper !== undefined) {
    $client_sliders.each(function (i, el) {
      let $this = $(el);
      let limit = $this.data('limit') === -1 ? 'auto' : $this.data('limit');

      new Swiper(el, {
        loop: false,
        speed: 500,
        spaceBetween: 0,
        slidesPerView: limit,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true,
        },
      });
    });
  }

  // Init Flexible Slider
  let $sliders = $('.js-slider');
  if ($sliders.length && typeof Swiper !== undefined) {
    $sliders.each(function (i, el) {
      let $this = $(el);
      let $section = $this.closest('.s-slider');
      let defaults = {
        loop: false,
        speed: 1000,
        spaceBetween: 0,
        slidesPerView: 1,
        navigation: {
          prevEl: $section.find('.swiper-button-prev').get(0),
          nextEl: $section.find('.swiper-button-next').get(0),
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        },
      };

      let options = $this.data('options') ?? {};

      new Swiper(el, { ...defaults, ...options });
    });
  }

  // Init Testimonials Slider
  let $testimonials = $('.js-testimonials');
  if ($testimonials.length && typeof Swiper !== undefined) {
    $testimonials.each(function (i, el) {
      let $this = $(el);
      let toShow = $this.data('show');
      let loop = $this.data('loop');
      let $section = $this.closest('.s-testimonials');
      let defaults = {
        loop: loop,
        speed: 1000,
        spaceBetween: 0,
        slidesPerView: 1,
        watchOverflow: false,
        navigation: {
          prevEl: $section.find('.swiper-button-prev').get(0),
          nextEl: $section.find('.swiper-button-next').get(0),
        },
        breakpoints: {
          768: {
            slidesPerView: toShow,
          },
        },
      };

      let options = $this.data('options') ?? {};

      new Swiper(el, { ...defaults, ...options });
    });
  }
}

function lazyLoadInit() {
  // Init LazyLoad (for ignore .no-lazyload).
  let lazyLoadInstance = new LazyLoad({
    elements_selector: 'img[data-lazy-src],.pre-lazyload',
    data_src: 'lazy-src',
    data_srcset: 'lazy-srcset',
    data_sizes: 'lazy-sizes',
    skip_invisible: false,
    class_loading: 'lazyloading',
    class_loaded: 'lazyloaded',
  });

  // Add tracking on adding any new nodes to body to update lazyload for the new images (AJAX for example).
  window.addEventListener(
    'LazyLoad::Initialized',
    function () {
      // Get the instance and puts it in the lazyLoadInstance variable.
      if (window.MutationObserver) {
        let observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            mutation.addedNodes.forEach(function (node) {
              if (typeof node.getElementsByTagName !== 'function') {
                return;
              }
              let images = node.getElementsByTagName('img');
              if (0 === images.length) {
                return;
              }
              lazyLoadInstance.update();
            });
          });
        });
        let b = document.getElementsByTagName('body')[0];
        let config = { childList: true, subtree: true };
        observer.observe(b, config);
      }
    },
    false
  );
}
